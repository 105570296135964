import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// Initialize Firebase
const firebaseConfig = {
	apiKey: "AIzaSyAkwj7gCrYLa17fTKyg5mYymbN9Y-3rtDg",
	authDomain: "fast-in-transit.firebaseapp.com",
	databaseURL: "https://fast-in-transit-default-rtdb.firebaseio.com",
	projectId: "fast-in-transit",
	storageBucket: "fast-in-transit.appspot.com",
	messagingSenderId: "702731676615",
	appId: "1:702731676615:web:aeb0a04e623159e26787e2"
  };

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();
const fieldValue = firebase.firestore.FieldValue;

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

// Ignore undefined properties
firebase.firestore().settings({
	ignoreUndefinedProperties: true,
});

export { auth, firestore, storage, functions, fieldValue, googleAuthProvider, githubAuthProvider, facebookAuthProvider, twitterAuthProvider };
