import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Col, Row, Typography } from "antd";
import { connect } from "react-redux";
import async from "async";
import { firestore } from "../../firebase/firebase";

function Customers(props) {
	const [clientID, setClientID] = useState("");
	const [coverages, setCoverages] = useState([]);

	useEffect(() => {
		setClientID(props.state?.client ?? null);
	}, [props]);


	useEffect(() => {
		onGetAllCoverages();
	}, []);

	const onGetAllCoverages = () => {
		firestore
			.collection("coverage")
			.where("active", "==", true)
			.onSnapshot(snapshot => {
				let allCoverages = [];
				async.eachSeries(
					snapshot.docs,
					(doc, callback) => {
						let data = doc.data();
						allCoverages.push({
							id: doc.id,
							name: data.name,
							type: data.type,
							price: props.auth.type === "Agent" || props.auth.type === "capturist" ? data.priceAgent : data.price,
							notes: data.notes,
							days: data.days,
							sr22: data.sr22 || false,
							pipe: data.pipe || false,
							vehicles: data.vehicles,
						});
						callback();
					},
					(err) => {
						if (!err) {
							setCoverages(allCoverages);
						}
					}
				);
			});
	};

	const clients = props.type === "truck" ? props.clients.filter(x => x.type === "Transmigrante") : props.clients;

	if (!clientID && props.route === "drafts") {
		return null;
	}

	return (
		<>
			<Row gutter={10}>
				<Col span={24}>
					<Typography.Title level={4}>Insurance Information</Typography.Title>
					<div className="gx-form-group">
						<AutoComplete disabled={props.state.editing}
							defaultValue={props.state.editing ?? clientID}
							style={{ width: props.state.editing ? "100%" : "94%" }}
							placeholder="Client*"
							dataSource={clients.map(item => {
								return (
									<AutoComplete.Option key={item.id} text={item.name} value={item.id}>
										{item.name}
									</AutoComplete.Option>
								);
							})}
							filterOption={(inputValue, option) => {
								return option.props.text.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
							}}
							onSelect={value => {
								const clientType = props.clients.find(x => x.id === value).type;
								console.log(coverages);
								const coveragesForThisTypeOfInsurance =
									props.type === "car" ? coverages.filter(x => x.type === "Car") : coverages.filter(x => x.type === "Truck");
								const selectedCoverages =
									clientType === "Turista"
										? coveragesForThisTypeOfInsurance.filter(x => x.days === 0)
										: coveragesForThisTypeOfInsurance.filter(x => x.days > 0);
								props.onChange({
									card: null,
									cards: [],
									client: value,
									clientName: props.clients.find(x => x.id === value).name,
									customer: props.clients.find(x => x.id === value).stripe,
									coverage: null,
									coverages: selectedCoverages,
								});
							}}
						/>
						{
							!props.state.editing && <Button
								style={{ marginTop: 10, marginLeft: 6 }}
								onClick={() => props.onChange({ addClient: true })}
								type={"primary"}
								shape="circle"
								icon={"plus"}
								size={"small"}
							/>
						}
					</div>
				</Col>
			</Row>
		</>
	);
}

const mapStateToProps = state => {
	return {
		clients: state.clients.contactList,
	};
};

export default connect(mapStateToProps)(Customers);
